import { useEffectOnce } from "../hooks/useEffectOnce";
import { useNavigate } from "react-router-dom";
import useRetryFetch from "../hooks/useRetryFetch";
import RenderDialog from "./RenderDialog";
// import $ from "jquery";
import "./Preview.css";

import { config } from "../Environment";
import { useState, useContext, useEffect } from "react";
import convertPrescription from "./reactflow/convert_prescription";
import LoggerContext from "../context/LoggerProvider";
import { WSContext } from "../App";
import useAuth from "../hooks/useAuth";
import { LANGUAGES } from "../lib/utils";

const Preview = () => {
  const retryFetch = useRetryFetch();
  const navigate = useNavigate();

  const { auth } = useAuth();

  const isWelcomeLocalStorage = () => {
    if (sessionStorage.getItem("isWelcome") === "true") {
      return true;
    }
    return false;
  };

  const [isWelcome] = useState(isWelcomeLocalStorage());

  const [diagram, setDiagram] = useState({});
  const [currentActivity, setCurrentActivity] = useState("");
  const [initialActivity, setInitialActivity] = useState("");
  const [initialStepStack, setInitialStepStack] = useState([]);
  const [userData, setUserData] = useState({});
  const [processId, setProcessId] = useState("");

  const { Logger } = useContext(LoggerContext);
  const { setListRefresh } = useContext(WSContext);

  const getQueryParams = () => {
    let result = {};

    let queryString = window.location.search;
    let keyValues = queryString.substring(1).split("&");

    for (let keyValue of keyValues) {
      let keyAndValue = keyValue.split("=");
      result[keyAndValue[0]] = keyAndValue[1];
    }
    Logger.debug(result);

    return result;
  };

  const getStartActivity = (diagram) => {
    let result = "";

    let activityKeys = Object.keys(diagram.data);
    for (let actID of activityKeys) {
      let actData = diagram.data[actID];
      if (actData.type === "start") {
        result = actID;
        break;
      }
    }

    return result;
  };

  useEffectOnce(() => {
    const showPreview = async () => {
      let queryParams = getQueryParams();

      if (queryParams.id) {
        // retrieve prescription from deeplink API
        let response = await fetch(
          `${config.API_BASE}/api/deeplinks/${queryParams.id}`
        );
        let presData = await response.json();

        // create process
        response = await retryFetch(
          `${config.API_BASE}/api/processes/${presData.prescriptionId}`,
          {
            method: "POST",
            headers: {
              "Content-Type": "application/json",
            },
            credentials: "include",
            body: JSON.stringify({
              email: auth.email,
            }),
          }
        );
        let process = await response.json();
        setProcessId(process.processId);

        let dia = convertPrescription(process.diagramJSON);
        const nextAct = getStartActivity(dia);
        setInitialActivity(nextAct);
        setInitialStepStack([]);
        setCurrentActivity(nextAct);

        Logger.debug(
          "runMode instantiated for shared link ",
          process.processId,
          nextAct
        );

        setDiagram(dia);

        // store prescriptionID and processID for design mode
        //sessionStorage.setItem("prescriptionID", presData.prescriptionId);
        sessionStorage.setItem("runProcessID", process.processId);
        sessionStorage.setItem("prescriptionHistoryID", presData.id);
      } else if (sessionStorage.getItem("draftprescription")) {
        // retrieve prescription directly

        let dia = convertPrescription(
          sessionStorage.getItem("draftprescription")
        );
        let startAct = getStartActivity(dia);
        setInitialActivity(startAct);
        setCurrentActivity(startAct);
        setInitialStepStack([]);
        setDiagram(dia);
      } else if (sessionStorage.getItem("prescriptionID")) {
        // retrieve prescription directly
        Logger.debug("Found prescriptionID in localStorage");

        // is it a running process (runMode) or a preview?
        if (
          sessionStorage.getItem("processID") ||
          sessionStorage.getItem("runProcessID")
        ) {
          // runMode

          let locProcessId =
            sessionStorage.getItem("processID") ||
            sessionStorage.getItem("runProcessID");
          Logger.debug(
            "runMode detected for processID of localStorage",
            locProcessId
          );
          setProcessId(locProcessId);

          let response = await retryFetch(
            `${config.API_BASE}/api/processes/${locProcessId}`
          );
          let process = await response.json();

          let nextAct = sessionStorage.getItem("nextActivity");
          Logger.debug(
            "nextAct from localStorage is",
            nextAct,
            typeof nextAct === "string"
              ? "CAREFUL: setItem to localStorage 'nextActivity 'was set which should NOT be the case"
              : typeof nextAct
          );
          let dia = convertPrescription(
            process.fiPrescriptionHistory.diagramJSON
          );
          if (!nextAct) {
            nextAct = getStartActivity(dia);
            Logger.debug("nextAct is start activity", nextAct);

            // update process
            if (locProcessId) {
              await retryFetch(
                `${config.API_BASE}/api/processes/${locProcessId}`,
                {
                  method: "PUT",
                  headers: {
                    "Content-Type": "application/json",
                  },
                  credentials: "include",
                  body: JSON.stringify({
                    status: "RUNNING",
                    nextActivity: nextAct,
                  }),
                }
              );
            }
          } else {
            // CONTINUE, get enterFlowdata
            setUserData(
              process.stepStack &&
                process.stepStack.length &&
                process.stepStack[process.stepStack.length - 1].userData
                ? process.stepStack[process.stepStack.length - 1].userData
                : {}
            );
          }
          setInitialActivity(nextAct);
          setCurrentActivity(nextAct);
          setInitialStepStack(process.stepStack ?? []);
          setDiagram(dia);
          sessionStorage.removeItem("processID");
          sessionStorage.removeItem("nextActivity");
        } else {
          // preview
          Logger.debug("previewMode detected");
          let response = await retryFetch(
            `${config.API_BASE}/api/prescriptions/${sessionStorage.getItem(
              "prescriptionID"
            )}`
          );
          let pres = await response.json();

          let dia = convertPrescription(pres.diagramJSON);
          let startAct = getStartActivity(dia);
          setInitialActivity(startAct);
          setCurrentActivity(startAct);
          setInitialStepStack([]);
          setDiagram(dia);
        }
      } else {
        alert("No diagram found");
      }
    };
    showPreview();

    return () => {
      sessionStorage.removeItem("isWelcome");
      Logger.warn(
        "PREVIEW IS UNMOUNTED!!!!!!!!!!!!!!!!!!--------------------!!!!!!!!!!!!!!!!!!!!-!_!!!!!----!!!!"
      );
    };
  }, []);

  const onDone = async (optionId, stepStack) => {
    Logger.debug("DONE Selected option: ", optionId);
    Logger.debug("DONE User data:");
    Logger.debug(userData);

    if (processId) {
      // create log entry
      Logger.debug("PROCESSLOG #1", processId, currentActivity, optionId);
      await retryFetch(`${config.API_BASE}/api/processlog/${processId}`, {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
        },
        credentials: "include",
        body: JSON.stringify({
          activity: currentActivity,
          selectedOptionId: optionId,
          userData: JSON.stringify(userData),
          message: "User action",
        }),
      });

      // update process and set to FINISHED
      await retryFetch(`${config.API_BASE}/api/processes/${processId}`, {
        method: "PUT",
        headers: {
          "Content-Type": "application/json",
        },
        credentials: "include",
        body: JSON.stringify({
          status: "FINISHED",
          nextActivity: "",
          stepStack, // retain full step stack so REOPEN is possible!
        }),
      });

      // => zurück zum alten Prozess, diagram laden, stepStack löschen, initialActivity anhand der Auswahl von hier neu setzen

      let response = await retryFetch(
        `${config.API_BASE}/api/processes/${processId}`
      );
      let processData = await response.json();

      let parentProcessId = processData.parentProcessId;

      if (!parentProcessId) return navigateFromPreview();

      while (parentProcessId) {
        let exitNextActivities = processData.exitNextActivities;

        // get diagram from parent!
        response = await retryFetch(
          `${config.API_BASE}/api/processes/${parentProcessId}`
        );
        let parentProcessData = await response.json();

        let parentDia = JSON.parse(
          parentProcessData.fiPrescriptionHistory.diagramJSON
        );

        Logger.debug("Stored exitOptions", exitNextActivities);
        let nextActivities = exitNextActivities.find(
          (option) => option.value === optionId
        ).nextActivities;
        Logger.debug(
          "Those are the next activities from the selected option",
          nextActivities
        );
        if (nextActivities.length) {
          parentProcessData.stepStack.push({
            activity: "REOPEN|" + processId,
            userData,
          });

          // update process
          await retryFetch(
            `${config.API_BASE}/api/processes/${parentProcessId}`,
            {
              method: "PUT",
              headers: {
                "Content-Type": "application/json",
              },
              credentials: "include",
              body: JSON.stringify({
                status: "RUNNING",
                nextActivity: nextActivities[0],
                stepStack: parentProcessData.stepStack,
                pendingInnerProcess: "",
              }),
            }
          );

          setProcessId(parentProcessId);

          setDiagram(parentDia);
          setInitialActivity(nextActivities[0]);
          setInitialStepStack(parentProcessData.stepStack);
          setCurrentActivity(nextActivities[0]);
          sessionStorage.removeItem("processID");
          sessionStorage.removeItem("nextActivity");

          parentProcessId = "";
        } else {
          // FINISH outer process, because there is no continuation!
          await retryFetch(
            `${config.API_BASE}/api/processes/${parentProcessId}`,
            {
              method: "PUT",
              headers: {
                "Content-Type": "application/json",
              },
              credentials: "include",
              body: JSON.stringify({
                status: "FINISHED",
                nextActivity: "",
                stepStack: [],
              }),
            }
          );

          // check if the outer process was nested
          response = await retryFetch(
            `${config.API_BASE}/api/processes/${parentProcessId}`
          );
          processData = await response.json();

          parentProcessId = processData.parentProcessId;

          if (!parentProcessId) {
            return navigateFromPreview();
          }
        }
      }
    } else {
      navigateFromPreview();
    }
  };

  const onPause = () => {
    // if (window.opener) {
    //   //sessionStorage.removeItem("prescriptionID");
    //   window.close();
    // } else if (isMobile) navigate("/activities");
    // else navigate("/design");
    //sessionStorage.removeItem("prescriptionID");
    navigateFromPreview();
  };

  const onNextActivity = async (optionId, nextActivity, stepStack) => {
    Logger.debug("Selected option: ", optionId);
    Logger.debug("User data:");
    Logger.debug(userData);
    Logger.debug("Next node will be: ", nextActivity);

    if (processId) {
      // create log entry
      Logger.debug(
        "------------- onNextActivity BEFORE process log --------------"
      );
      Logger.debug("PROCESSLOG #3", processId, currentActivity, optionId);
      await retryFetch(`${config.API_BASE}/api/processlog/${processId}`, {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
        },
        credentials: "include",
        body: JSON.stringify({
          activity: currentActivity,
          selectedOptionId: optionId,
          userData: JSON.stringify(userData),
          message: "User action",
        }),
      });

      Logger.debug(
        "------------- onNextActivity BEFORE data item mapping --------------",
        userData
      );
      // consider data item mapping?
      let newUserData = structuredClone(userData);
      if (diagram.data[nextActivity].dataitemmapping) {
        for (let mapping of diagram.data[nextActivity].dataitemmapping) {
          // mapping is {key, description, mappedToOuter:{key, description}}
          if (mapping.mappedToOuter.key) {
            newUserData[mapping.key] = newUserData[mapping.mappedToOuter.key];
          }
        }
        setUserData(newUserData);

        stepStack[stepStack.length - 1].userData = newUserData;
        Logger.debug("AFTER ADDING DATA ITEM MAPPING", newUserData);
      }

      Logger.debug(
        "------------- onNextActivity BEFORE process update --------------",
        userData,
        newUserData
      );
      // update process
      await retryFetch(`${config.API_BASE}/api/processes/${processId}`, {
        method: "PUT",
        headers: {
          "Content-Type": "application/json",
        },
        credentials: "include",
        body: JSON.stringify({
          status: "RUNNING",
          nextActivity,
          stepStack,
        }),
      });
      setCurrentActivity(nextActivity);
    }
  };

  const onNestedActivity = async (
    presHistoryId,
    optionsWithHandles,
    outerActivityId
  ) => {
    Logger.debug(
      "PREVIEW received switching to nested activity with",
      presHistoryId,
      optionsWithHandles,
      outerActivityId
    );
    if (processId) {
      // determine nextActivities from optionHandles
      let exitOptions = [];
      for (let opt of optionsWithHandles) {
        let nextActivities = [];
        let handle = opt.handle;
        for (let edge of diagram.edges) {
          if (edge.sourceHandle === handle) {
            nextActivities.push(edge.target);
          }
        }
        exitOptions.push({
          value: opt.value,
          nextActivities,
        });
      }

      Logger.debug("Determined next activities", exitOptions);

      Logger.debug(
        "------------- onNestedActivity BEFORE creating new nested process --------------",
        userData
      );
      // -> Create a new process (with parent process!)
      let response = await retryFetch(
        `${config.API_BASE}/api/processes/${presHistoryId}/${processId}`,
        {
          method: "POST",
          headers: {
            "Content-Type": "application/json",
          },
          credentials: "include",
          body: JSON.stringify({
            email: auth.email,
            exitNextActivities: exitOptions,
            outerActivityId,
          }),
        }
      );

      let process = await response.json();

      let pendingInnerProcess = process.processId;

      Logger.debug(
        "------------- onNestedActivity BEFORE setting outer process to pending --------------"
      );
      // set to pending
      response = await retryFetch(
        `${config.API_BASE}/api/processes/setpending/${processId}`,
        {
          method: "PUT",
          headers: {
            "Content-Type": "application/json",
          },
          credentials: "include",
          body: JSON.stringify({
            pendingInnerProcess,
          }),
        }
      );

      setProcessId(pendingInnerProcess);

      // -> set diagram, initial activity and initial step stack (leer?) for RenderDialog
      let dia = convertPrescription(process.diagramJSON);
      const nextAct = getStartActivity(dia);

      Logger.debug(
        "------------- onNestedActivity BEFORE setting start activity and TERMINATE step stack entry --------------",
        userData
      );
      // update new process with start activity
      await retryFetch(
        `${config.API_BASE}/api/processes/${pendingInnerProcess}`,
        {
          method: "PUT",
          headers: {
            "Content-Type": "application/json",
          },
          credentials: "include",
          body: JSON.stringify({
            status: "RUNNING",
            nextActivity: nextAct,
            stepStack: [{ activity: "TERMINATE", userData: userData }],
          }),
        }
      );

      setInitialActivity(nextAct);
      setInitialStepStack([{ activity: "TERMINATE", userData: userData }]);
      setCurrentActivity(nextAct);
      setDiagram(dia);

      sessionStorage.removeItem("processID");
      sessionStorage.removeItem("nextActivity");
    } else {
    }
  };

  const onBackFromNested = async () => {
    Logger.debug(
      "Coming back from nested activity => TERMINATE inner activity and continue on previous step from outer activity"
    );
    // get process information from outer process (processId)
    // step stack to pop latest activity from there, diagram from parentDia
    // terminate inner process (parentProcessId)

    let response = await retryFetch(
      `${config.API_BASE}/api/processes/${processId}`
    );
    let innerProcessData = await response.json();
    let parentProcessId = innerProcessData.parentProcessId;

    // create log entry for inner process
    Logger.debug("PROCESSLOG #4", processId, currentActivity, "GOINGBACK");
    await retryFetch(`${config.API_BASE}/api/processlog/${processId}`, {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
      },
      credentials: "include",
      body: JSON.stringify({
        activity: currentActivity,
        selectedOptionId: "GOINGBACK",
        userData: JSON.stringify(userData),
        message: "User action",
      }),
    });

    response = await retryFetch(
      `${config.API_BASE}/api/processes/${parentProcessId}`
    );
    let processData = await response.json();

    let oldStepStack = processData.stepStack;

    let nextStepFromOuter = oldStepStack.pop();

    let theActivity =
      typeof nextStepFromOuter === "string"
        ? nextStepFromOuter
        : nextStepFromOuter.activity;

    Logger.debug(
      "After POPPING last step from step stack",
      nextStepFromOuter,
      oldStepStack
    );

    setUserData(nextStepFromOuter.userData ? nextStepFromOuter.userData : {});

    response = await retryFetch(
      `${config.API_BASE}/api/processes/${processId}`,
      {
        method: "DELETE",
        credentials: "include",
      }
    );

    // update outer process
    await retryFetch(`${config.API_BASE}/api/processes/${parentProcessId}`, {
      method: "PUT",
      headers: {
        "Content-Type": "application/json",
      },
      credentials: "include",
      body: JSON.stringify({
        status: "RUNNING",
        nextActivity: theActivity,
        stepStack: oldStepStack,
        pendingInnerProcess: "",
      }),
    });

    setProcessId(parentProcessId);
    setDiagram(JSON.parse(processData.fiPrescriptionHistory.diagramJSON));
    setInitialActivity(theActivity);
    setInitialStepStack(oldStepStack);
    setCurrentActivity(theActivity);
    sessionStorage.removeItem("processID");
    sessionStorage.removeItem("nextActivity");
  };

  const onBackToFinishedNested = async (nestedProcessId, stepStack) => {
    // current process is the outer one
    // stepStack is already popped (REOPEN step removed) and must be written to this process
    // pendingInnerProcess must be set again to nestedProcessId
    // nextActivity must be set to outerActivityId of nested process
    // Retrieve nested process information and get nextActivity popped from its step stack (write step stack back?)
    // nested process must be RUNNING again, log entry to change activity and to running

    let response = await retryFetch(
      `${config.API_BASE}/api/processes/${nestedProcessId}`
    );
    let nestedProcessData = await response.json();

    let nextNestedActivity = nestedProcessData.stepStack.pop();

    let theActivity =
      typeof nextNestedActivity === "string"
        ? nextNestedActivity
        : nextNestedActivity.activity;

    Logger.debug(
      "Outer process needs this new information: pendingInnerProcess, stepStack, nextActivity",
      nestedProcessId,
      stepStack,
      nestedProcessData.outerActivityId
    );

    // update outer process
    await retryFetch(
      `${config.API_BASE}/api/processes/updateouterfromreopen/${processId}`,
      {
        method: "PUT",
        headers: {
          "Content-Type": "application/json",
        },
        credentials: "include",
        body: JSON.stringify({
          nextActivity: nestedProcessData.outerActivityId,
          stepStack,
          pendingInnerProcess: nestedProcessId,
        }),
      }
    );

    // log entry for outer process
    Logger.debug("PROCESSLOG #5", processId, currentActivity, "GOINGBACK");
    await retryFetch(`${config.API_BASE}/api/processlog/${processId}`, {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
      },
      credentials: "include",
      body: JSON.stringify({
        activity: currentActivity,
        selectedOptionId: "GOINGBACK",
        userData: JSON.stringify(userData),
        message: "User action",
      }),
    });

    Logger.debug(
      "Nested process needs to be made RUNNING with nextActivity, stepStack",
      nextNestedActivity,
      nestedProcessData.stepStack
    );

    setUserData(nextNestedActivity.userData ? nextNestedActivity.userData : {});

    // update nested process and reopen
    await retryFetch(
      `${config.API_BASE}/api/processes/reopen/${nestedProcessId}`,
      {
        method: "PUT",
        headers: {
          "Content-Type": "application/json",
        },
        credentials: "include",
        body: JSON.stringify({
          nextActivity: theActivity,
          stepStack: nestedProcessData.stepStack,
        }),
      }
    );

    // log entry for nested process
    Logger.debug(
      "PROCESSLOG #6",
      nestedProcessId,
      nextNestedActivity,
      "REOPENED"
    );
    await retryFetch(`${config.API_BASE}/api/processlog/${nestedProcessId}`, {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
      },
      credentials: "include",
      body: JSON.stringify({
        activity: nextNestedActivity.activity,
        selectedOptionId: "REOPENED",
        userData: JSON.stringify(userData),
        message: "User action",
      }),
    });

    setProcessId(nestedProcessId);
    setDiagram(JSON.parse(nestedProcessData.fiPrescriptionHistory.diagramJSON));
    setInitialActivity(theActivity);
    setInitialStepStack(nestedProcessData.stepStack);
    setCurrentActivity(theActivity);
    sessionStorage.removeItem("processID");
    sessionStorage.removeItem("nextActivity");
  };

  const navigateFromPreview = () => {
    Logger.debug("isWelcome", isWelcome);
    if (isWelcome || sessionStorage.getItem("runProcessID")) {
      navigate("/portal");
    } else {
      setListRefresh(true);
      navigate(-1);
    }
  };

  useEffect(() => {
    if (!Object.keys(userData).length) {
      Logger.warn(
        "INITIAL USER DATA IS EMPTY !!!!!!!!!!!!!!---------------!!!!!!!!!-!_!__-!!!!!!!!!!!!!!"
      );
    }
  }, [userData]);

  return (
    <>
      <div
        id="preview"
        className="preview"
        dir={
          LANGUAGES[diagram.prescriptionLanguage]?.dir
            ? LANGUAGES[diagram.prescriptionLanguage].dir
            : "ltr"
        }
      >
        {!isWelcome && <div className="background"></div>}
        <RenderDialog
          diagram={diagram}
          initialActivity={initialActivity}
          initialStepStack={initialStepStack}
          userData={userData}
          setUserData={setUserData}
          onDone={onDone}
          onPause={onPause}
          onNextActivity={onNextActivity}
          onNestedActivity={onNestedActivity}
          onBackFromNested={onBackFromNested}
          onBackToFinishedNested={onBackToFinishedNested}
          processId={processId}
        />
      </div>
    </>
  );
};

export default Preview;
