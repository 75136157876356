import { useState, useContext, useMemo, forwardRef, useRef, useImperativeHandle, createRef } from "react";
import { Component } from "react";
import { withTranslation, WithTranslation, useTranslation  } from "react-i18next";
// import { MyContext } from "./reactflow/MyContextProvider";
import { Dialog, DialogContent, DialogHeader, DialogTitle } from "./ui/dialog";
import { config } from "../Environment";
import { Button } from "./ui/button";
import useRetryFetch from "../hooks/useRetryFetch";
import { useEffectOnce } from "../hooks/useEffectOnce";
import "./WorkflowPermissionDialog.css";
import { Checkbox } from "./ui/checkbox";
import { Label } from "./ui/label";
import { toast } from "sonner";

interface WorkflowCollaborator {
    userId: string,
    role: string,
}
interface WorkflowClient {
    userId: string,
}
interface WorkflowInvitedCollaborator {
    email: string,
    role: string,
}
interface WorkflowInvitedClient {
    email: string,
}
interface WorkflowPermissions {
    fiWorkflow: string;
    accessMode: string;
    clients: WorkflowClient[];
    collaborators: WorkflowCollaborator[];
    invited_collaborators: WorkflowInvitedCollaborator[];
    invited_clients: WorkflowInvitedClient[];
}

interface WorkflowPermissionDialogPropTypes extends WithTranslation {
    open: boolean;
    onOpenChange: (value: boolean) => void;
    workflowId: string;
}
interface WorkflowPermissionDialogStateTypes {
    open: boolean;
    onOpenChange: (value: boolean) => void;
    accessMode: string;
    clients: WorkflowClient[];
    collaborators: WorkflowCollaborator[];
    invitedClients: WorkflowInvitedClient[];
    invitedCollaborators: WorkflowInvitedCollaborator[];
    //ref: any;
}

const WF_ACCESS_MODE_PUBLIC = 'public';
const WF_ACCESS_MODE_CLIENT = 'client';
const WF_ACCESS_MODE_RESTRICTED = 'restricted';
const LIST_WF_ACCESS_MODE = [WF_ACCESS_MODE_PUBLIC,WF_ACCESS_MODE_CLIENT,WF_ACCESS_MODE_RESTRICTED];
const WF_DEFAULT_ACCESS_MODE = WF_ACCESS_MODE_CLIENT;

const WorkflowPermissionDialogContent = ({workflowId}: {workflowId: string})  => {
    const actionRef = useRef(null);
    const { t } = useTranslation();
    const [accessMode, setAccessMode] = useState(WF_DEFAULT_ACCESS_MODE);
    const [collaborators, setCollaborators] = useState<WorkflowCollaborator[]>([]);
    const [clients, setClients] = useState<WorkflowClient[]>([]);
    const [invitedCollaborators, setInvitedCollaborators] = useState<WorkflowInvitedCollaborator[]>([]);
    const [invitedClients, setInvitedClients] = useState<WorkflowInvitedClient[]>([]);
    const retryFetch = useRetryFetch();
    const getWorkflowPermissions = async (): Promise<WorkflowPermissions> => {
        let response = await retryFetch(
            `${config.API_BASE}/api/workflowPermission/${workflowId}`,
            {
            method: "GET",
            }
        );
        let result = await response.json();
        console.log(`getWorkflowPermissions result ${JSON.stringify(result)}`);
        return result as Promise<WorkflowPermissions>;
    };
    const notifySaved = () => {
        toast.success(t("msg_saved"));
    };
    const notifyInvited = (email: string) => {
        toast.success(t("msg_invited_with_email", { email }));
    };
    const notifyRemoved = (email: string) => {
        toast.success(t("msg_removed_with_email", { email }));
    };
    const notifyError = () => {
        toast.error(t("msg_error_generic"));
    };
    const inviteCollaborator = async (email: string, role: string) => {
        try {
            let response = await retryFetch(
                `${config.API_BASE}/api/workflowPermission/${workflowId}`,
                {
                    method: "POST",
                    headers: {
                        'Accept': 'application/json',
                        'Content-Type': 'application/json',
                    },
                    body: JSON.stringify({
                        action: 'invite_collaborator',
                        email,
                        role
                    }),
                },
            );
            console.log(`inviteCollaborator result ${JSON.stringify(response.status)}`);
            notifyInvited(email);
            // setInvitedCollaborators([...invitedCollaborators, { email, role }]);
            await loadWorkflowPermissions();
        } catch (err) {
            console.error(`inviteCollaborator failed`, err);
            notifyError();
        }
    };
    const editCollaborator = async (email: string, role: string) => {
        try {
        let response = await retryFetch(
            `${config.API_BASE}/api/workflowPermission/${workflowId}`,
            {
                method: "POST",
                headers: {
                    'Accept': 'application/json',
                    'Content-Type': 'application/json',
                },
                body: JSON.stringify({
                    action: 'edit_collaborator',
                    email,
                    role
                }),
            },
        );
        console.log(`editCollaborator result ${JSON.stringify(response.status)}`);
            notifySaved();
            await loadWorkflowPermissions();
        } catch (err) {
            console.error(`editCollaborator failed`, err);
            notifyError();
        }
    };
    const removeCollaborator = async (email: string) => {
        try {
        let response = await retryFetch(
            `${config.API_BASE}/api/workflowPermission/${workflowId}`,
            {
                method: "POST",
                headers: {
                    'Accept': 'application/json',
                    'Content-Type': 'application/json',
                },
                body: JSON.stringify({
                    action: 'remove_collaborator',
                    email,
                }),
            },
        );
        console.log(`removeCollaborator result ${JSON.stringify(response.status)}`);
        notifyRemoved(email);
        await loadWorkflowPermissions();
    } catch (err) {
            console.error(`removeCollaborator failed`, err);
            notifyError();
        }
    };
    const inviteClient = async (email: string, role: string) => {
        try {
            let response = await retryFetch(
                `${config.API_BASE}/api/workflowPermission/${workflowId}`,
                {
                    method: "POST",
                    headers: {
                        'Accept': 'application/json',
                        'Content-Type': 'application/json',
                    },
                    body: JSON.stringify({
                        action: 'invite_client',
                        email
                    }),
                },
            );
            console.log(`inviteClient result ${JSON.stringify(response.status)}`);
            notifyInvited(email);
            await loadWorkflowPermissions();
        } catch (err) {
            console.error(`inviteClient failed`, err);
            notifyError();
        }
    };
    const removeClient = async (email: string) => {
        try {
        let response = await retryFetch(
            `${config.API_BASE}/api/workflowPermission/${workflowId}`,
            {
                method: "POST",
                headers: {
                    'Accept': 'application/json',
                    'Content-Type': 'application/json',
                },
                body: JSON.stringify({
                    action: 'remove_client',
                    email,
                }),
            },
        );
        console.log(`removeClient result ${JSON.stringify(response.status)}`);
            notifyRemoved(email);
            await loadWorkflowPermissions();
        } catch (err) {
            console.error(`removeClient failed`, err);
            notifyError();
        }
    };
    const changeAccessMode = async (value: string) => {
        try {
            let response = await retryFetch(
                `${config.API_BASE}/api/workflowPermission/${workflowId}`,
                {
                    method: "POST",
                    headers: {
                        'Accept': 'application/json',
                        'Content-Type': 'application/json',
                    },
                    body: JSON.stringify({
                        action: 'edit_access_mode',
                        accessMode: value,
                    }),
                },
            );
            console.log(`changeAccessMode response status ${JSON.stringify(response.status)}`);
            setAccessMode(value);
            notifySaved();
        } catch (err) {
            console.error(`changeAccessMode failed with value ${value}`, err);
            notifyError();
        }
    };
    const loadWorkflowPermissions = async () => {
        const result = await getWorkflowPermissions();
        setAccessMode(result.accessMode);
        setClients(result.clients);
        setCollaborators(result.collaborators);
        setInvitedClients(result.invited_clients);
        setInvitedCollaborators(result.invited_collaborators);
    };
    /*
    useImperativeHandle(ref, () => {
        return {
            async load() {
                const result = await getWorkflowPermissions();
                setAccessMode(result.accessMode);
                setClients(result.clients);
            },
        };
    }, []);
    */
   /*
            <option value="restricted">Restricted</option>
        {accessMode == 'restricted' && <p>{t('helptext_workflow_access_mode_restricted')}</p> }
        and then TODO - if it's restricted, add an input field and list of clients who ower allowed to is it
   */
        useEffectOnce(() => {
            (async function () {
                await loadWorkflowPermissions();
            })();
          }, []);
    return (<div>
        <p>{t('prompt_workflow_access_mode')}</p>
        <select value={accessMode} onChange={(e) => changeAccessMode(e.target.value)}>
            <option value="public">{t('workflow_access_mode_public')}</option>
            <option value="client">{t('workflow_access_mode_client')}</option>
        </select>
        {accessMode == 'public' && <p>{t('helptext_workflow_access_mode_public')}</p> }
        {accessMode == 'client' && <p>{t('helptext_workflow_access_mode_client')}</p> }
    </div>);
};

class WorkflowPermissionDialog extends Component<WorkflowPermissionDialogPropTypes,WorkflowPermissionDialogStateTypes> {
    constructor(props: WorkflowPermissionDialogPropTypes) {
        super(props);
        this.state = {
            open: false,
            onOpenChange: () => {},
            accessMode: '',
            clients: [],
            collaborators: [],
            invitedClients: [],
            invitedCollaborators: [],
            //ref: createRef(),
        };
    }
    componentDidUpdate(prevProps: WorkflowPermissionDialogPropTypes, prevState: WorkflowPermissionDialogStateTypes) {
        //console.log(`componentDidUpdate for workflow permissions dialog props ${JSON.stringify(this.props)} and prev pops ${JSON.stringify(prevProps)}}`);
        if (this.props.open && !prevProps.open) {
            //console.log('opened workflow permissions dialog');
            //this.state.ref.current.load();
        }
    }
    /*
    handleSaveButton() {
        //setShowDialog(false);
        this.props.onOpenChange(false);
    }
    handleCancelButton() {
        //setShowDialog(false);
        this.props.onOpenChange(false);
    }
        */

  render() {
    const { t, open, onOpenChange, workflowId } = this.props;
    return (
<Dialog open={open} onOpenChange={onOpenChange}>
    <DialogContent>
        <DialogHeader>
            <DialogTitle>{t("manage_permissions")}</DialogTitle>
        </DialogHeader>
        <WorkflowPermissionDialogContent workflowId={workflowId}/>
    </DialogContent>
</Dialog>
    );
  }

}

const Wrapped = withTranslation()(WorkflowPermissionDialog);

export default Wrapped;
