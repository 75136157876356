import { useState, useEffect } from "react";
import { useTranslation } from "react-i18next";
import { useLocation, useNavigate } from "react-router-dom";
import "./Register.css";

import { config } from "../Environment";
import { Label } from "./ui/label";
import { Button } from "./ui/button";
import { Input } from "./ui/input";

function ResetPassword() {
  const navigate = useNavigate();

  const location = useLocation();

  const queryParams = new URLSearchParams(location.search);
  const token = queryParams.get("token");
  const userId = queryParams.get("id");
  const from = location.state?.from.pathname;

  const { t } = useTranslation();

  const [pwd, setPwd] = useState("");
  const [pwd2, setPwd2] = useState("");
  const [errMsg, setErrMsg] = useState("");
  const [success, setSuccess] = useState(false);

  useEffect(() => {
    setErrMsg("");
  }, [pwd]);

  const handleSubmit = async (e) => {
    e.preventDefault();

    if (!pwd || !pwd2) {
      return setErrMsg(t("msg_error_email_and_password_required"));
    }

    if (pwd !== pwd2) {
      return setErrMsg(t("msg_error_passwords_dont_match"));
    }

    let response = await fetch(config.API_BASE + "/api/auth/reset-password", {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
      },
      body: JSON.stringify({
        token,
        userId,
        password: pwd,
      }),
      credentials: "include",
    });
    if (!response.ok) {
      setErrMsg(t("msg_generic_error"));
    } else {
      setPwd("");
      setPwd2("");
      setSuccess(true);
      window.setTimeout(() => {
        navigate(
          "/login",
          from ? { state: { from: { pathname: from } } } : undefined
        );
      }, 2000);
    }
  };

  const changePassword = (e) => {
    setPwd(e.target.value);
  };

  const changePassword2 = (e) => {
    setPwd2(e.target.value);
  };

  return (
    <div className="h-full w-full flex items-center justify-start py-12">
      <div className="mx-auto grid w-[350px] gap-6">
        <div className="grid gap-2 text-center">
          <h1 className="text-3xl font-bold">{t("reset_password")}</h1>
        </div>
        <form onSubmit={handleSubmit}>
          <div className="grid gap-4">
            <div className="grid gap-2">
              <Label htmlFor="password">{t("password")}</Label>
              <Input
                id="password"
                type="password"
                onChange={changePassword}
                value={pwd}
                required
              />
            </div>
            <div className="grid gap-2">
              <Label htmlFor="password2">{t("password_repeat")}</Label>
              <Input
                id="password2"
                type="password"
                onChange={changePassword2}
                value={pwd2}
                required
              />
            </div>
            {errMsg ? <div className="error">{errMsg}</div> : ""}
            {success ? (
              <div className="success">{t("msg_reset_successful")}</div>
            ) : (
              ""
            )}
            <Button type="submit" className="w-full">
              {t("submit")}
            </Button>
          </div>
        </form>
      </div>
    </div>
  );
}

export default ResetPassword;
