import React, { useRef, useState, useContext, useEffect } from "react";
import "./App.css";
import RequireAuth from "./components/RequireAuth";
//import { BrowserRouter as Router, Routes, Route } from "react-router-dom";
import {
  unstable_HistoryRouter as Router,
  Routes,
  Route,
} from "react-router-dom";
import Header from "./components/Header";
import Home from "./components/Home";
import Login from "./components/Login";
import LoginFromGoogle from "./components/LoginFromGoogle";
import Register from "./components/Register";
import Welcome from "./components/Welcome";
import DesignViewWrapper from "./components/DesignViewWrapper";
import Preview from "./components/Preview";
import ActivitiesList from "./components/ActivitiesList";
import Portal from "./components/Portal";
import Setup from "./components/Setup";
import Users from "./components/Users";
import Review from "./components/Review";
import ReadOnlyViewWrapper from "./components/ReadOnlyViewWrapper";
import { useEffectOnce } from "./hooks/useEffectOnce";
import { distributionService } from "./services/distributionService";
import { directionService } from "./services/directionService";
import customhistory from "./history/customhistory";
import ForgotPassword from "./components/ForgotPassword";
import ResetPassword from "./components/ResetPassword";
import LoggerContext from "./context/LoggerProvider";
import { DirectionProvider } from "@radix-ui/react-direction";
import { PresListProvider } from "./context/PresListProvider";
import Dashboard from "./components/Dashboard";
import { QueryClientProvider } from "@tanstack/react-query";
import Accept from "./components/Accept";
import { queryClient } from "./lib/queryClient";

export const WSContext = React.createContext();

function App() {
  const [socket, setSocket] = useState(null);
  const [roomId, setRoomId] = useState(0);
  const [listRefresh, setListRefresh] = useState(false);
  const [shadcnDirection, setShadcnDirection] = useState(document.body.dir);

  const [globalAbortController, setGlobalAbortController] = useState(
    new AbortController()
  );

  const { Logger } = useContext(LoggerContext);

  const ws = useRef();
  const roomRef = useRef(null);

  useEffectOnce(() => {
    // unset semaphore
    sessionStorage.removeItem("semaphore");

    // chat server connection, get current host
    Logger.debug("process.env", process.env);
    let host = window.location.host;
    let origin = window.location.origin;
    if (host.indexOf("localhost:3") !== -1)
      host = "ws://" + host.split(":")[0] + ":8090";
    else if (origin.indexOf("https:") !== -1)
      host = "wss://" + process.env.REACT_APP_API_BASE.split("//").pop();
    else host = "ws://" + host;
    host += '/api/websocket';

    Logger.info("Connecting to chat server on host " + host);
    ws.current = new WebSocket(host);

    setSocket(ws.current);

    ws.current.onopen = () => {
      Logger.info("Chat connection opened");

      // was it a page refresh but email/accessToken are still in localStorage? Then send "login" to websocket
      let emailAddress = localStorage.getItem("email");
      if (emailAddress) {
        ws.current.send(
          JSON.stringify({
            command: "login",
            payload: {
              email: emailAddress,
            },
          })
        );
      }
    };

    ws.current.onmessage = (e) => {
      const msg = JSON.parse(e.data);
      Logger.info(
        "Chat message received ... distributing to listening components",
        msg
      );

      if (msg.command === "userlist")
        distributionService.sendUserlist(msg.payload);
      else distributionService.sendMessage(msg);
    };

    let directionUnsubscribe = directionService
      .getDirectionChange()
      .subscribe(() => {
        setShadcnDirection(document.body.dir);
      });

    return () => {
      Logger.error("UNLOADING App.js ... normally this should not happen!");
      Logger.info("Chat close connection");
      ws.current.close();
      directionUnsubscribe.unsubscribe();
    };
  }, []);

  useEffect(() => {
    roomRef.current = roomId;
  }, [roomId]);

  return (
    <div className="text-black h-screen w-full flex flex-col">
      <WSContext.Provider
        value={{
          socket,
          roomId,
          setRoomId,
          roomRef,
          listRefresh,
          setListRefresh,
          globalAbortController,
          setGlobalAbortController,
        }}
      >
        <QueryClientProvider client={queryClient}>
          <DirectionProvider dir={shadcnDirection}>
            <PresListProvider>
              <Router history={customhistory}>
                <Header />
                <main className="flex-grow">
                  <Routes>
                    <Route path="/" element={<Home />} />
                    <Route path="/login" element={<Login />} />
                    <Route
                      path="/loginfromgoogle"
                      element={<LoginFromGoogle />}
                    />
                    <Route path="/register" element={<Register />} />
                    <Route
                      path="/forgotpassword"
                      element={<ForgotPassword />}
                    />
                    <Route path="/passwordReset" element={<ResetPassword />} />
                    <Route path="/preview" element={<Preview />} />
                    <Route path="/readonly" element={<ReadOnlyViewWrapper />} />
                    <Route path="/accept/:id" element={<Accept />} />
                    <Route element={<RequireAuth />}>
                      <Route path="/setup" element={<Setup />} />
                      <Route path="/users" element={<Users />} />
                      <Route path="/welcome" element={<Welcome />} />
                      <Route path="/portal" element={<Portal />} />
                      <Route path="/review" element={<Review />} />
                      <Route path="/dashboard" element={<Dashboard />} />
                      <Route path="/activities" element={<ActivitiesList />} />
                      <Route path="/design" element={<DesignViewWrapper />} />
                      {/* <Route path="/fulllog" element={<ShowFullLog />} /> */}
                    </Route>
                  </Routes>
                </main>
              </Router>
            </PresListProvider>
          </DirectionProvider>
        </QueryClientProvider>
      </WSContext.Provider>
    </div>
  );
}

export default App;
