import useAuth from "./useAuth";
import { config } from "../Environment";

const useRefreshToken = () => {
  const { setAuth } = useAuth();

  const refresh = async () => {
    localStorage.removeItem("accessToken");
    // localStorage.removeItem("email");
    let response = await fetch(config.API_BASE + "/api/auth/refresh", {
      method: "GET",
      credentials: "include",
    });
    if (!response.ok) Promise.reject(response.status);
    const data = await response.json();
    setAuth((prevAuth) => {
      return { ...prevAuth, accessToken: data.accessToken };
    });
    localStorage.setItem("accessToken", data.accessToken);
    // localStorage.setItem("email", data.email);
    return data.accessToken;
  };

  return refresh;
};

export default useRefreshToken;
