import { useState, useRef, useEffect } from "react";
import { useTranslation } from "react-i18next";
import { Link, useLocation, useNavigate } from "react-router-dom";
import "./Register.css";
import { Label } from "./ui/label";
import { Button } from "./ui/button";
import { Input } from "./ui/input";
import { config } from "../Environment";
import { directionService } from "../services/directionService";

import signInGoogle from "../images/SignInGoogle.png";

function Register() {
  const navigate = useNavigate();

  const location = useLocation();
  const from = location.state?.from.pathname;
  const acceptId = location.state?.acceptId;

  const emailRef = useRef();

  const { t } = useTranslation();

  useEffect(() => {
    emailRef.current.focus();
    let directionUnsubscribe = directionService
      .getDirectionChange()
      .subscribe(() => {
        setDirection(document.body.dir);
      });

    return () => {
      directionUnsubscribe.unsubscribe();
    };
  }, []);

  const [email, setEmail] = useState("");
  const [pwd, setPwd] = useState("");
  const [pwd2, setPwd2] = useState("");
  const [errMsg, setErrMsg] = useState("");
  const [success, setSuccess] = useState(false);
  const [direction, setDirection] = useState(document.body.dir);

  useEffect(() => {
    setErrMsg("");
  }, [email, pwd]);

  const handleSubmit = async (e) => {
    e.preventDefault();

    if (!email || !pwd) {
      emailRef.current.focus();
      return setErrMsg(t("msg_error_email_and_password_required"));
    }

    if (pwd !== pwd2) {
      return setErrMsg(t("msg_error_passwords_dont_match"));
    }

    let response = await fetch(config.API_BASE + "/api/auth/register", {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
      },
      body: JSON.stringify({
        email: email,
        password: pwd,
      }),
      credentials: "include",
    });
    if (!response.ok) {
      setErrMsg(t("msg_error_already_registered"));
      emailRef.current.focus();
    } else {
      setEmail("");
      setPwd("");
      setPwd2("");
      setSuccess(true);
      window.setTimeout(() => {
        navigate(
          "/login",
          from ? { state: { from: { pathname: from }, acceptId } } : undefined
        );
      }, 2000);
    }
  };

  const changeEmail = (e) => {
    setEmail(e.target.value);
  };

  const changePassword = (e) => {
    setPwd(e.target.value);
  };

  const changePassword2 = (e) => {
    setPwd2(e.target.value);
  };

  return (
    <div className="h-full w-full flex items-center justify-start py-12">
      <div className="mx-auto grid w-[350px] gap-6">
        <div className="grid gap-2 text-center">
          <h1 className="text-3xl font-bold">{t("sign_up")}</h1>
        </div>
        <form onSubmit={handleSubmit}>
          <div className="grid gap-4">
            <div className="grid gap-2">
              <Label htmlFor="email">{t("email_address")}</Label>
              <Input
                id="email"
                type="email"
                placeholder="m@example.com"
                onChange={changeEmail}
                ref={emailRef}
                value={email}
                required
              />
            </div>
            <div className="grid gap-2">
              <Label htmlFor="password">{t("password")}</Label>
              <Input
                id="password"
                type="password"
                onChange={changePassword}
                value={pwd}
                required
              />
            </div>
            <div className="grid gap-2">
              <Label htmlFor="password2">{t("password_repeat")}</Label>
              <Input
                id="password2"
                type="password"
                onChange={changePassword2}
                value={pwd2}
                required
              />
            </div>
            {errMsg ? <div className="error">{errMsg}</div> : ""}
            {success ? (
              <div className="success">{t("msg_register_successful")}</div>
            ) : (
              ""
            )}
            <Button type="submit" className="w-full">
              {t("sign_up")}
            </Button>
          </div>
        </form>
        <div className="mt-3 text-center text-sm">
          {t("already_have_an_account")}
          <Link to="/login" state={{ acceptId }} className="underline ms-2">
            {t("login")}
          </Link>
        </div>
        <button
          className={`googlelogin${direction}`}
          onClick={() => {
            if (location.state) {
              sessionStorage.setItem(
                "locationstate",
                JSON.stringify(location.state)
              );
            }
            window.open(`${config.API_BASE}/auth/google/redirect`, "_self");
          }}
        >
          <img src={signInGoogle} alt="Google login" title="Google login" />
          {t("login_with_google")}
        </button>
      </div>
    </div>
  );
}

export default Register;
