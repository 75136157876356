import { useState, useContext, useMemo } from "react";
import { useTranslation } from "react-i18next";
import { MyContext } from "./reactflow/MyContextProvider";
import MultiSelect from "./ui/multiselect";
import "./DesignMenu.css";

function DesignMenu({
  onClick,
  initialPresName,
  setPresName,
  unsavedChanges,
  setUnsavedChanges,
}: {
  onClick: (e: any) => void;
  initialPresName: string;
  setPresName: (name: string) => void;
  unsavedChanges: boolean;
  setUnsavedChanges: (unsaved: boolean) => void;
}) {
  const { t } = useTranslation();

  const { flowContext, contract, setContract } = useContext(MyContext);

  const [editMode, setEditMode] = useState(false);
  const [newPresName, setNewPresName] = useState("");

  const editModeOn = () => {
    setEditMode(true);
  };

  const editModeOff = () => {
    setEditMode(false);
  };

  const changeNewPresName = (e: React.ChangeEvent<HTMLInputElement>) => {
    setNewPresName(e.target.value);
    setPresName(e.target.value);
    setUnsavedChanges(true);
  };

  const handleKeyDown = (e: React.KeyboardEvent<HTMLInputElement>) => {
    if (e.key === "Enter" || e.key === "Escape") {
      editModeOff();
    }
  };

  const convertFlowContext = useMemo(() => {
    let locFlowContext = structuredClone(flowContext);
    let result = locFlowContext
      .filter(
        (entry: any) =>
          !entry.type ||
          entry.type.startsWith("text") ||
          entry.type === "gauge" ||
          entry.type === "timer"
      )
      .map((entry: any) => {
        return {
          label: entry.description,
          value: entry.key,
          type:
            !entry.type || entry.type.startsWith("text") ? "text" : entry.type,
        };
      });
    return result;
  }, [flowContext]);

  return (
    <div className="verticalMenu mt-4 mx-2.5">
      <div className="presName">
        <h5>
          {unsavedChanges ? "* " : " "}
          {editMode && !initialPresName ? (
            <input
              type="text"
              value={newPresName}
              onChange={changeNewPresName}
              onKeyDown={handleKeyDown}
              autoFocus={true}
            />
          ) : (
            <span
              onClick={() => {
                if (!initialPresName) {
                  editModeOn();
                }
              }}
            >
              {initialPresName
                ? initialPresName
                : newPresName
                  ? newPresName
                  : t("no_title")}
            </span>
          )}
        </h5>
      </div>
      <ul onClick={onClick}>
        <li data-id="new">{t("new_activity")}</li>
        <li data-id="add">{t("add_activity")}</li>
        <li data-id="addnestedprescription">{t("add_nested_activity")}</li>
        <li data-id="validate">{t("validate_activity")}</li>
        <li data-id="save">{t("save_activity")}</li>
        <li data-id="saveas">{t("save_activity_as")}</li>
        <li data-id="zoomin">{t("zoom_plus")}</li>
        <li data-id="zoomout">{t("zoom_minus")}</li>
        <li data-id="fittoview">{t("fit_to_view")}</li>
        <li data-id="run">{t("run")}</li>
        <li data-id="preview">{t("preview")}</li>
        <li data-id="share">{t("share")}</li>
        <li data-id="manage_permissions">{t("manage_permissions")}</li>
      </ul>
      <span>{t("contract")}:</span>
      {convertFlowContext.length && contract && setContract ? (
        <MultiSelect
          options={convertFlowContext as any}
          value={contract}
          onChange={(val: any) => {
            setUnsavedChanges(true);
            setContract(val);
          }}
          emptyIndicator={
            <p className="text-center text-lg leading-10 text-gray-600 dark:text-gray-400">
              no more results.
            </p>
          }
          className="w-[300px]"
        />
      ) : (
        <></>
      )}
    </div>
  );
}

export default DesignMenu;
