import { config } from "../Environment";
import { useEffectOnce } from "../hooks/useEffectOnce";
import { useContext, useState } from "react";
import LoggerContext from "../context/LoggerProvider";
import useRetryFetch from "../hooks/useRetryFetch";
import "./Users.css";

interface UserItem {
  _id: string;
  email: string;
  roles: string[];
}
interface Result {
  list: UserItem[];
}


function Users() {
  const { Logger } = useContext(LoggerContext);

  const [checklist, setChecklist] = useState<UserItem[]>([]);
  const retryFetch = useRetryFetch();

  async function fetchAllUsers(): Promise<Result> {
    try {
      const result = await retryFetch(config.API_BASE + '/api/sysadmin/users', {
        method: 'get',
        headers: {
          'Accept': 'application/json',
        },
      });
      const resultJson = result.json();
      Logger.debug(resultJson);
      return resultJson as Promise<Result>;
    } catch (err) {
      Logger.error('Failed to search users ' + err);
      return {list: []};
    }
  }

  useEffectOnce(() => {
    // call the setup API
    const setup = async () =>  {
      const result = await fetchAllUsers();
      if (Array.isArray(result.list)) {
        setChecklist(result.list);
      }
    };
    setup();
  }, []);

  return <div className="container">
    <div className="content">
    <h1>Users</h1>
    <ul>
      {checklist.map((item: UserItem, idx) => (
        <li key={idx}>{item.email} {JSON.stringify(item.roles)}</li>
      ))}
    </ul>
    </div>
  </div>;
}

export default Users;
